import PropTypes from 'prop-types';
import {
  faFacebookF,
  faLinkedinIn,
  faTelegram,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const socialLinks = [
  {
    icon: faFacebookF,
    url: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  {
    icon: faTwitter,
    url: 'https://twitter.com/intent/tweet?text=@greentechapps&url=',
  },
  {
    icon: faLinkedinIn,
    url: 'https://www.linkedin.com/shareArticle?url=',
  },
  {
    icon: faWhatsapp,
    url: 'whatsapp://send?text=',
  },
  {
    icon: faTelegram,
    url: 'https://telegram.me/share/url?url=',
  },
];

const BlogSharing = ({ canonicalUrl }) => (
  <div className="my-4 text-2xl text-center md:my-8">
    {socialLinks.map((link) => (
      <a
        key={link.url}
        href={link.url + canonicalUrl}
        target="_blank"
        rel="noreferrer"
        className="mx-3"
      >
        <FontAwesomeIcon icon={link.icon} />
      </a>
    ))}
  </div>
);

BlogSharing.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
};

export default BlogSharing;
